import React from "react";
import img from "../images/hero1.jpg";

const Hero = () => {
  return (
    <div className="max-w-[1640px] mx-auto p-4">
      <div className="max-h-[500px] relative flex flex-col items-center sm:flex-row bg-amber-100">
        {/* overlay */}
        <div className="absolute w-full h-full text-gray-200 flex flex-col justify-center items-center">
          <h1 className="mt-[-80px] font-bold text-2xl sm:text-3xl">
            <span>I</span> HAVE
          </h1>
          <h1 className="mt-4 font-bold text-2xl sm:text-3xl">A GOD!</h1>
        </div>
        <img src={img} alt="" className="mx-auto" />
      </div>
    </div>
  );
};

export default Hero;
