import React from "react";
import { FaFacebook, FaMapMarkerAlt, FaPhone } from "react-icons/fa"; // Import the phone icon

const Footer = () => {
  const facebookPageUrl =
    "https://www.facebook.com/groups/2164591183724035/?ref=share&mibextid=KtfwRi";
  const mapUrl =
    "https://www.google.com/maps/place/Dunkirk+Community+Centre/@52.9397736,-1.1817281,17z/data=!4m6!3m5!1s0x4879c2115e38a077:0xdc15052406fb10d0!8m2!3d52.9399793!4d-1.1817303!16s%2Fg%2F1tjdl8kh?hl=en&entry=ttu";

  return (
    <div className="flex flex-col sm:flex-row bg-amber-500 text-white mt-10 p-5">
      <div className="sm:flex-1 h-20 text-center">
        <a href={facebookPageUrl} target="_blank" rel="noopener noreferrer">
          <FaFacebook size={40} className="m-5 mx-10" />
        </a>
      </div>
      <div className="sm:flex-1 h-20 text-center">
        <a href={mapUrl} target="_blank" rel="noopener noreferrer">
          <FaMapMarkerAlt size={40} className="m-5 mx-10" />
          <p className="pb-5">Google-Map</p>
        </a>
      </div>
      <div className="sm:flex-1 h-20 text-center flex items-center">
        {" "}
        {/* Added a flex container */}
        <FaPhone size={30} className="mr-2" /> {/* Phone icon */}
        <p>: +447446343678</p>
      </div>
      <div className="sm:flex-1 h-20 text-center ">
        Dunkirk Community Center <br />
        near Queens Medical Center <br />
        Montpelier Road
        <br />
        Nottinham, NG& 2JW UK
      </div>
    </div>
  );
};

export default Footer;
