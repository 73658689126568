import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-amber-500 p-4 sticky top-0 z-10">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="text-white font-bold text-lg sm:text-2xl">TNCF</div>
        <div className="text-white font-bold text-lg ml-20 sm:text-2xl">
          "I HAVE A GOD FOR WHOM NOTHING IS IMPOSSIBLE."
        </div>
        <div className="sm:hidden">
          <button
            onClick={toggleMenu}
            className="text-white hover:text-gray-200 focus:outline-none"
          >
            ☰ {/* Use a Unicode character for the menu icon (e.g., ☰) */}
          </button>
        </div>
        <ul className={`sm:flex space-x-3 ${isOpen ? "block" : "hidden"}`}>
          <li className="inline">
            <Link to="/" className="text-white hover:text-gray-200">
              Home
            </Link>
          </li>
          <li className="inline">
            <Link to="/contact" className="text-white hover:text-gray-200">
              Contact Us
            </Link>
          </li>
          <li className="inline">
            <Link to="/gallery" className="text-white hover:text-gray-200">
              Gallery
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
