import React from "react";
import img1 from "../images2/img1.jpg";
import img2 from "../images2/img2.jpg";
import img3 from "../images2/img3.jpg";
import img4 from "../images2/img4.jpg";

const Timings = () => {
  return (
    <div className="flex flex-col sm:flex-row m-10">
      <div className="sm:flex-1 h-45 bg-amber-100 border-b sm:border-r border-black">
        <div>
          <div className="max-w-[1640px] mx-auto p-4">
            <div className="max-h-[500px] relative flex items-center">
              {/* overlay */}
              <div className="absolute w-full h-full text-gray-200 flex flex-col justify-center items-center">
                <h1 className="mt-[-80px] text-black text-2xl sm:text-2xl">
                  "The heavens declare the glory of God;
                  <br />
                  the skies proclaim the work of his hands."
                </h1>
              </div>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="sm:flex-1 h-45 bg-amber-100 border-b sm:border-r border-black">
        <div>
          <div className="max-w-[1640px] mx-auto p-4">
            <div className="max-h-[500px] relative flex items-center">
              {/* overlay */}
              <div className="absolute w-full h-full text-gray-200 flex flex-col justify-center items-center">
                <h1 className="mt-[-80px] font-bold text-2xl sm:text-2xl">
                  <span>
                    "Your word is a lamp <br />
                    to my feet and a light to <br />
                    my path."
                  </span>
                </h1>
              </div>
              <img src={img2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="sm:flex-1 h-45 bg-amber-100 border-b sm:border-r border-black">
        <div>
          <div className="max-w-[1640px] mx-auto p-4">
            <div className="max-h-[500px] relative flex items-center">
              {/* overlay */}
              <div className="absolute w-full h-full text-gray-200 flex flex-col justify-center items-center">
                <h1 className="mt--[-80px] text-black text-2xl sm:text-2xl">
                  <span>
                    {" "}
                    "Cast all your anxiety on him because he cares for you."
                  </span>
                </h1>
              </div>
              <img src={img3} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="sm:flex-1 h-45 bg-amber-100">
        <div>
          <div className="max-w-[1640px] mx-auto p-4">
            <div className="max-h-[500px] relative flex items-center">
              {/* overlay */}
              <div className="absolute w-full h-full text-gray-200 flex flex-col justify-center items-center">
                <h1 className="mt-80px text-2xl sm:text-2xl">
                  "I lift up my eyes to the hills. From where does my help come?
                  : <br />
                  My help comes from the Lord, the Maker of heaven and earth."
                </h1>
              </div>
              
              <img src={img4} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timings;
