import React from "react";

const Words = () => {
  const transparentStyle = {
    opacity: "0.7", // Adjust this value for the desired level of transparency (0 to 1)
  };
  return (
    <div className="flex flex-col sm:flex-row m-10">
      <div className="flex-1 h-40 bg-orange-400 items-center justify-center">
        <h1 className="text-2xl" style={transparentStyle}>
          SUNDAY WORSHIP <br />
          <br />
          10.00AM-12:30PM
        </h1>
      </div>
      <div className="flex-1 h-40 bg-orange-100 items-center justify-center">
        <h1 className="text-2xl" style={transparentStyle}>
          <span>
            COTTAGE MEETING <br />
            <br /> WEDNESDAY 10.00AM-12:30PM
          </span>
        </h1>
      </div>
      <div className="flex-1 h-40 bg-orange-200 items-center justify-center">
        <h1 className="text-2xl " style={transparentStyle}>
          <span>
            BIBLE <br />
            CLASS
            <br />
            <br />
            MONDAY: 5.00PM
          </span>
        </h1>
      </div>
      <div className="flex-1 h-40 bg-orange-300 items-center justify-center">
        <h1 className=" text-2xl" style={transparentStyle}>
          SUNDAY SCHOOL:
          <br /> <br />
          after CHURCH: 1:00PM to 2:00PM
        </h1>
      </div>
    </div>
  );
};

export default Words;
