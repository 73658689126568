import React, { useState } from "react";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ivg1 from "../churchmem/pic1.jpg";
import ivg2 from "../churchmem/pic2.jpg";
import ivg3 from "../churchmem/pic3.jpg";
import ivg4 from "../churchmem/pic4.jpg";
import ivg5 from "../churchmem/pic5.jpg";
import ivg6 from "../churchmem/pic6.jpg";
import ivg7 from "../churchmem/pic7.jpg";
import ivg8 from "../churchmem/pic8.jpg";
import ivg9 from "../churchmem/pic9.jpg";
import ivg10 from "../churchmem/pic10.jpg";
const CustomGallery = () => {
  // Initialize your images in the state
  const [images, setImages] = useState([
    {
      original: ivg1, // Use ivg1 directly
      description: "Image 1",
    },
    {
      original: ivg2, // Use ivg2 directly
      description: "Image 2",
    },
    {
      original: ivg3, // Use ivg2 directly
      description: "Image 2",
    },
    {
      original: ivg4, // Use ivg2 directly
      description: "Image 2",
    },
    {
      original: ivg5, // Use ivg2 directly
      description: "Image 2",
    },
    {
      original: ivg6, // Use ivg2 directly
      description: "Image 2",
    },
    {
      original: ivg7, // Use ivg2 directly
      description: "Image 2",
    },
    {
      original: ivg8, // Use ivg2 directly
      description: "Image 2",
    },
    {
      original: ivg9, // Use ivg2 directly
      description: "Image 2",
    },
    {
      original: ivg10, // Use ivg2 directly
      description: "Image 2",
    },
    // Add more images as needed
  ]);

  // Function to add new images to the gallery
  const addImage = () => {
    setImages([
      ...images,
      {
        original: ivg3, // Use ivg3 directly
        description: "New Image",
      },
    ]);
  };

  return (
    <div className="p-4 sm:p-6 md:p-8 lg:p-10">
      <div className="flex flex-col items-center">
        <button onClick={addImage} className="mb-4">
          GAllERY
        </button>
        <Gallery items={images} />
      </div>
    </div>
  );
};

export default CustomGallery;
