import React from "react";

const Writing = () => {
  const transparentStyle = {
    opacity: "0.7", // Adjust this value for the desired level of transparency (0 to 1)
  };
  return (
    <div className="flex flex-col m-10 bg-amber-100">
      <h1 className="text-black sm:text-xl p-4" style={transparentStyle}>
        Jesus walked among the people, teaching profound truths of love,
        compassion, and forgiveness. He performed miracles that left hearts in
        awe and inspired faith in the hearts of many. His ultimate act of love
        was to bear the weight of humanity's sins on a rugged cross, bridging
        the chasm between mankind and the divine.
        <br />
        <br />
        In return for this profound sacrifice, the people of this land were
        called to embrace a life of faith, guided by the light of His teachings.
        They were to love one another, to offer forgiveness, and to share the
        good news of salvation. As they journeyed through life's trials and
        tribulations, they carried the hope of a heavenly promise. The road to
        this heavenly realm was not paved with gold but with acts of kindness,
        humility, and selflessness. It was a path illuminated by faith, a path
        that led them closer to their Creator, and a path that led them to a
        paradise where love and grace reigned eternal. And so, in the pages of
        this divine tale, the story of Jesus inspired countless hearts to seek
        the path to heaven and the fulfillment of their faith.
      </h1>
    </div>
  );
};

export default Writing;
