import React from "react";

const Contact = () => {
  return (
    <div className="p-4 sm:p-6">
      <div className="bg-amber-100 p-6 sm:p-12 md:p-20 m-4 sm:m-10">
        <p className="text-center sm:text-left">
          EMAIL ADDRESS: tncfnottingham@yahoo.com <br />
          PHONE NUMBER: +447446343678 <br />
          ALTERNATE NUMBER: +447570192202
        </p>
      </div>
    </div>
  );
};

export default Contact;
