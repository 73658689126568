import React from "react";
import img3 from "../churchteamimg/churchteam.jpg";

const Churchteam = () => {
  const transparentStyle = {
    opacity: "0.7", // Adjust this value for the desired level of transparency (0 to 1)
  };
  return (
    <div className="max-w-[1640px] mx-auto p-4">
      <div className="max-h-[500px] relative flex flex-col items-center sm:flex-row bg-amber-100">
        {/* overlay */}
        <div className="absolute w-full h-full text-white flex flex-col justify-center items-center">
          <h1
            className="mt-[-80px] text-white-400 text-2xl font-bold sm:text-3xl"
            style={transparentStyle}
          >
            <span>OUR</span> VALUABLE
          </h1>
          <h1
            className="mt-80px font-bold text-white-400 text-2xl sm:text-3xl"
            style={transparentStyle}
          >
            MEMBERS OF FAITH
          </h1>
        </div>
        <img src={img3} alt="" className="mx-auto" />
      </div>
    </div>
  );
};

export default Churchteam;
