import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import Contact from "./components/contact";
import Timings from "./components/Timings";
import Words from "./components/words";
import Churchteam from "./components/churchteam";
import Writing from "./components/writing";
import Footer from "./components/footer";
import Gallery from "./components/gallery";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

function Home() {
  return (
    <>
      <Hero />
      <Timings />
      <Words />
      <Churchteam />
      <Writing />
    </>
  );
}

export default App;
